import React from "react";
import "../assets/styles/bannerCards.css";
import driverAppIcon from "../assets/images/driverAppIcon.svg";
import googlePlayIcon from "../assets/images/googlePlayIcon.svg";
import AppstoreIcon from "../assets/images/AppstoreIcon.svg";
import { options } from "../constants";
import { useSelector } from "react-redux";
const DriverCardDesktop = () => {
  const language = useSelector((state) => state.language);
  return (
    <div
      className="driver-card-part driver-card-part-desktop"
      style={{
        width: "50%",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        className="card-banner"
        style={{
          position: "absolute",
          right: "30px",
          top: "40px",
        }}
      >
        <div
          className="left"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            padding: "20px",
            backgroundColor: "white",
          }}
        >
          <span className="span-card-banner-1">{options[language].driver}</span>
          <span
            className="span-card-banner-2"
            style={{
              marginTop: "20px",
            }}
          >
            {options[language].lowComisions}
          </span>
          <span className="span-card-banner-2">
            {options[language].moreReve}
          </span>
          {/* <span
            className="span-card-banner-3"
            style={{
              marginTop: "15px",
            }}
          >
            {options[language].forEveryRide}
          </span>
          <span className="span-card-banner-3">
            <span className="span-card-banner-4  small-right-4">
              0.30 <span className="span-card-banner-5">AZN</span>
            </span>{" "}
            {options[language].comision}
          </span> */}
          <span
            className="span-card-banner-4"
            style={{
              marginTop: language !== "ru" ? "150px" : "110px",
              fontSize: "52px",
            }}
          >
            + 2000{" "}
            <span className="span-card-banner-5">
              {options[language].forAzn}
            </span>
          </span>
          <span className="span-card-banner-3">
            {options[language].additionalReven}
          </span>
        </div>
      </div>
      <div
        className="type-info"
        style={{
          position: "absolute",
          left: "192px",
          bottom: "100px",
        }}
      >
        <img src={driverAppIcon} alt="driver app icon" />
        <span>{options[language].bidDriver}</span>
      </div>
      <div
        className="type-urls"
        style={{
          position: "absolute",
          left: "192px",
          bottom: "40px",
        }}
      >
        <a
          href="https://play.google.com/store/apps/details?id=com.bidriver"
          target="_blank"
        >
          <img src={googlePlayIcon} alt="google play icon" />
        </a>
        <a
          href="https://apps.apple.com/az/app/bid-driver/id6497953366"
          target="_blank"
        >
          <img src={AppstoreIcon} alt="app store icon" />
        </a>
      </div>
    </div>
  );
};

export default DriverCardDesktop;
