import React, { useEffect, useRef, useState } from "react";
import Banner from "../components/Banner";
import WhyBid from "../components/WhyBid";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import About from "../components/About";
import How from "../components/How";
import ebazVertical from "../components/Banners/ebazVertical.png"
import gezzVertical from "../components/Banners/gezzVertical.png"
import ebazHorizontal from "../components/Banners/ebazHorizontal.png"
import gezzHorizontal from "../components/Banners/gezzHorizontal.png"
function Home() {
  const [navBg, setNavBg] = useState(false);
  const changeNavBg = () => {
    window.scrollY >= 230 ? setNavBg(true) : setNavBg(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);
  const catRef = useRef();

  return (
    <>
      <div className={`about-project d l ${navBg ? "active" : ""}`}>
        <a href="https://ebaz.az" target="_blank">
          <img src={ebazVertical} alt="ebaz" />
        </a>
      </div>
      <div className={`about-project d r ${navBg ? "active" : ""}`}>
        <a href="https://gezz.az" target="_blank">
          <img src={gezzVertical} alt="gezz" />
        </a>
      </div>
      
      <Banner btnRef={catRef} />
      <div className={`mobile-about-project`}>
        <a href="https://ebaz.az" target="_blank">
          <img src={ebazHorizontal} alt="ebaz" />
        </a>
      </div>
      <div ref={catRef}>
        <WhyBid />
      </div>
      <How />
      <div className={`mobile-about-project`}>
        <a href="https://gezz.az" target="_blank">
          <img src={gezzHorizontal} alt="gezz" />
        </a>
      </div>
      <About />
      <Faq />
      <Footer />
    </>
  );
}

export default Home;
